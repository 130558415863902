.player-list {
    margin: 0;
    padding: 0;
    max-height: 570px;
    overflow-y: auto;
    background: var(--list-color);
    color: var(--light);
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.player-list li {
    list-style: none;
    padding: 15px 35px;
    display: block;
    background: var(--list-item-color);
    cursor: pointer;
    text-align: left;
    margin: 5px;
}

.player-list p {
    color: var(--light);
    padding: 10px 15px;
    margin-bottom: 0px;
}

.player-list::-webkit-scrollbar {
    width: 20px;
}

.player-list::-webkit-scrollbar-track {
    border-radius: 10px;
}

.player-list::-webkit-scrollbar-thumb,
.lives-list::-webkit-scrollbar-thumb {
    background: var(--accent);
    border: 5px solid var(--list-item-color);
    border-radius: 10px;
}

.lives-list::-webkit-scrollbar {
    height: 20px;
}

.lives-list::-webkit-scrollbar-track {
    border-radius: 10px;
    margin-left: 15px;
    margin-right: 15px;
}

.lives-list {
    width: 100%;
    height: 17vh;
    overflow-x: scroll;
    margin: 4px 0px;
    padding-bottom: 8px;
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem
}

.lives-list::-webkit-scrollbar-thumb {
    border: none;
    background-color: var(--list-item-color);
}

.lives-list::-webkit-scrollbar {
    height: 10px;
    padding-top: 5px;
}

.playerlist-icon {
    height: 100%;
    aspect-ratio: 1/1;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background-color: var(--list-item-mobile-color);
    display: inline-flex;
    padding: 1rem;    
    cursor: pointer;
}

.playerlist-icon>img {
    width: 90%;
    filter: grayscale(100%);
}

#target video {
    height: 100%;
}

.watermark {
    height: 50px;
    width: 100%;
    object-fit: contain;
    object-position: center;
}

.selected-mobile>img {
    filter: none !important;
}

li.selected,
.selected-mobile {
    background-image: linear-gradient(45deg, #6a3f26, var(--dark)) !important;
}

.player {
    aspect-ratio: 16/9;
    position: relative;
    overflow: hidden;
    background-color: transparent;
    transition: 0.3s;
    width: 100%;
}

.player:hover,
.player.hover {
    background-color: #00000074;
    transition: 0.3s;
}

.controls {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    transition: 0.3s;
    opacity: 0;
    z-index: 10;
}

.controls:hover,
.controls.hover {
    opacity: 0.9 !important;
    transition: 0.3s;
}

.controls.fullscreen {
    opacity: 0 !important;
    transition: 0.3s;
}

.controls i {
    transition: 0.3s;
    cursor: pointer;
}

.controlli,
.spinner-border {
    filter: drop-shadow(2px 2px 5px #3d3d3d5c);
}

.fondo {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    padding: 6px 12px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.80) 100%);
}

.fondo>div:nth-child(2) {
    padding-top: 6px;
}

.controls i:hover,
.progress:hover {
    filter: drop-shadow(0px 0px 6px #ffffff36);
    transition: 0.3s;
}

input[type=range] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    height: 3px;
    border-radius: 3px;
    cursor: pointer;
    z-index: 2;
}

.back {
    z-index: 0;
    height: 4px;
    background-color: #00000050;
}

.progress {
    height: 4px;
    background-color: var(--light);
    z-index: 2;
    border-radius: 0;
}

input[type=range],
.back {
    position: absolute;
    width: 100%;
}

input[type=range]::-webkit-slider-thumb {
    appearance: none;
    height: 10px;
    width: 10px;
    background-color: var(--light);
    border-radius: 100%;
}

input[type=range]::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.play {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* VIDEO */
#video {
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: contain;
}

.loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.live-full::-webkit-media-controls-panel {
    display: none !important;
    opacity: 0.9 !important;
}

.iphone-fscreen {
    position: fixed;
    width: 100vw;
    z-index: 999999;
    top: 0;
    left: 0;
}

.loader {
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* :state(webkit-media-controls-play-button),
:state(webkit-media-controls-panel),
:state(webkit-media-controls-start-playback-button) {
    appearance: none;
    display: none !important;
    -webkit-appearance: none;
} */

#player {
    min-height: 400px;
    width: 100%;
}

.lista {
    z-index: 100;
}

@media screen and (max-width: 960px) {
    .watermark {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 1000px) {
    .canale {
        display: none;
    }
}

@media screen and (max-width: 990px) {
    #player {
        min-height: auto;
    }
}