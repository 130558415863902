@keyframes scroll {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    25% {
        opacity: 1;
        transform: translateY(10px);
    }

    80% {
        opacity: 1;
        transform: translateY(10px);
    }

    100% {
        opacity: 0;
        transform: translateY(10px);
    }
}

.scroll-cta {
    position: absolute;
    padding: 20px;
    left: 50%;
    bottom: 5%;
    z-index: 2;
    font-size: 1.5rem;
    transform: translateX(-50%);
    animation: scroll 3s infinite;
}

.scroll-cta i {
    display: flex;
}

.hex-transition {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
}