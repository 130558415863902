/* You can add global styles to this file, and also import other style files */
/* Bootstrap Icons */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

/* Material Icons */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

/* MontSerrat */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/* Uppy */
@import "@uppy/core/dist/style.css";
@import "@uppy/dashboard/dist/style.css";

/* NgZorro */
@import "ng-zorro-antd/ng-zorro-antd.min.css";
@import 'assets/css/ng-zorro.css';

/* Player CSS */
@import 'assets/css/player.css';

/* VidStack */
@import 'vidstack/player/styles/default/theme.css';
@import 'vidstack/player/styles/default/layouts/audio.css';
@import 'vidstack/player/styles/default/layouts/video.css';

/* Scroll */
@import 'assets/css/scroll.css';

/* TailWind */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --accent: #f4792c !important;
    --light: #ffffff !important;
    --dark: #000001 !important;
    --header-color: #031018 !important;
    --secondary-button-color: #dedfdf !important;
    --list-color: #1d1f27 !important;
    --list-item-color: #1c2b38 !important;
    --list-item-mobile-color: #031018 !important;
    --muted-color: #6c757D !important;
    --shadow-color: #3d3d3d !important;
    --nav-height: 65px;
}

html,
body {
    height: 100%;
    scroll-behavior: smooth;
    color: var(--light);
    margin: 0;
    font-family: "Montserrat", sans-serif !important;
    font-optical-sizing: auto !important;
    font-style: normal !important;
    background-color: var(--dark);
}

.contenuto {
    min-height: calc(100vh - 185px);
    display: flex;
    align-items: center;
    color: var(--dark);
    padding-bottom: 35px;
}

.crop {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.text-muted {
    color: var(--muted-color) !important;
}

.my-uppy-dashboard .uppy-Dashboard-Item-previewInnerWrap {
    background-color: var(--accent) !important;
}

.cursor-pointer {
    cursor: pointer;
}

/* CONTENUTO */

.bg-image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height: calc(100vh - var(--nav-height));
}

/* CONTENUTO */

/* BOTTONE */


.bottone,
.bottone-secondario {
    color: var(--light);
    background-color: var(--accent);
    border: 0px;
    padding: 10px 25px;
    font-weight: bolder;
    font-size: 1.15rem;
    height: auto;
    text-transform: uppercase;
    border-color: transparent;
}

.bottone-secondario {
    background-color: var(--secondary-button-color);
}

.bottone:focus,
.bottone-secondario:focus,
.bottone:focus-visible,
.bottone-secondario:focus-visible,
.ant-btn-primary:focus,
.ant-btn-primary:focus-visible {
    color: var(--light);
    background-color: var(--accent);
}

.bottone:hover,
.bottone-secondario:hover,
.ant-btn-primary:hover {
    color: var(--dark);
    background-color: var(--accent);
    border-color: transparent
}

.bottone-secondario:hover {
    color: var(--accent);
    background-color: var(--secondary-button-color);
}

/* BOTTONE */

/* FORM */

.forms-inputs input,
.forms-inputs textarea {
    height: 50px;
    border: 1px solid var(--dark);
    color: var(--dark);
    width: 100%;
    padding: 10px;
}

.forms-inputs textarea {
    height: auto;
}

.forms-inputs input:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid var(--dark)
}

/* FORM */

.testo {
    text-shadow: 0px 0px 5px var(--shadow-color);
}

.grecaptcha-badge {
    visibility: hidden !important;
}

.h-full-nav {
    min-height: calc(100vh - var(--nav-height));
}

@media screen and (min-width: 991px) {
    .contenuto {
        min-height: calc(100vh - var(--nav-height)) !important;
    }
}