.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
    border: 1px solid var(--dark);
    width: 100%;
    padding: 10px;
    color: var(--dark);
}

.ant-select-selection-item {
    line-height: 27px !important;
}

.ant-table-content {
    overflow: auto;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
    line-height: 30px;
}

.ant-progress-text {
    color: var(--light);
}

.ant-btn-primary {
    color: var(--light);
    background-color: var(--accent);
    border: 0px;
}

.spinner>div>.ant-spin {
    display: flex;
    align-items: center;
    justify-content: center;
    mix-blend-mode: multiply;
    min-height: calc(100vh - var(--nav-height));
}

.forms-inputs .ant-input-affix-wrapper input,
.forms-inputs nz-date-picker input {
    height: auto;
    border: 0px solid;
    padding: 0px;
}

.forms-inputs .ant-input-affix-wrapper input:focus,
.forms-inputs nz-date-picker input:focus,
.forms-inputs nz-date-picker input:hover {
    outline: none;
    border: none
}

.forms-inputs nz-input-group,
.forms-inputs nz-date-picker {
    height: 50px;
    border: 1px solid var(--dark);
    color: var(--dark);
    width: 100%;
    padding: 10px;
}

.news-divider {
    border-top: 1px solid rgb(255 255 255 / 50%) ;
}

.ondemand-divider.ant-divider-with-text::after, .ondemand-divider.ant-divider-with-text::before {
    border-top-color: var(--light);
    top: 0%
}

.ondemand-divider .ant-divider-inner-text {
    padding: 10px;
    border: 1px solid var(--light);
    border-radius: 50%;
}

.tooltip-label svg {
    vertical-align: 0rem;
}

.home .ant-card-body {
    height: 175px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ant-card-head-title {
    padding: 24px 0px !important;
}

nz-table .ant-pagination {
    margin-bottom: 0px !important;
}

nz-form-item {
    @apply flex !flex-col items-start !mb-0 w-full;
}

nz-form-item nz-form-control {
    @apply w-full
}